<template>
  <svg aria-hidden="true"  class="svg-icon-spin">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<script lang="ts" setup>
  const props = defineProps({
    prefix: {
      type: String,
      default: 'icon'
    },
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: '20px'
    },
    align: {
      type: String,
      default: 'middle'
    }
  })
  const symbolId = computed(() => `#${props.prefix}-${props.name}`)
</script>
<style lang="scss" scoped>
  .svg-icon-spin {
    width: v-bind(size);
    height: v-bind(size);
    fill: currentColor;
    vertical-align: v-bind(align);
    flex-shrink: 0;
    outline: 0;
  }
</style>
